import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StatisticDTO} from '../../model/dto/statistic.dto';
import {ReportingDto} from '../../model/dto/reporting.dto';
import {ReportingBarCard} from '../../model/reporting/bar/reporting-bar-card.model';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class StatisticsWebservice {
  private readonly root = `${environment.envApiUrl}/statistics`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient,
              private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  getRegistrationStatistics(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    if (date) {
      return this.http.post<StatisticDTO>(
        `${this.root}/inscription/${date.getMonth()}/${date.getFullYear()}`,
        listOfIdPlace
      );
    } else {
      return this.http.post<StatisticDTO>(`${this.root}/inscription`, listOfIdPlace);
    }
  }

  getRegistrationSexeStatistics(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    return this.http.post<StatisticDTO>(
      `${this.root}/inscription/sexe/${date.getMonth()}/${date.getFullYear()}`,
      listOfIdPlace
    );
  }

  getPresenceStatistics(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {

    const token = getMobileToken();

    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      if (date) {
        return this.httpMobile.post<StatisticDTO>(
          `${this.root}/presence/${date.getMonth()}/${date.getFullYear()}`,
          listOfIdPlace,
          options
        );
      } else {
        return this.httpMobile.post<StatisticDTO>(`${this.root}/presence`, listOfIdPlace, options);
      }
    } else {
      if (date) {
        return this.http.post<StatisticDTO>(
          `${this.root}/presence/${date.getMonth()}/${date.getFullYear()}`,
          listOfIdPlace
        );
      } else {
        return this.http.post<StatisticDTO>(`${this.root}/presence`, listOfIdPlace);
      }
    }
  }

  getPresenceTrancheStatistics(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    const token = getMobileToken();

    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.post<StatisticDTO>(
        `${this.root}/presence/tranche/${date.getMonth()}/${date.getFullYear()}`,
        listOfIdPlace, options
      );
    }else {
      return this.http.post<StatisticDTO>(
        `${this.root}/presence/tranche/${date.getMonth()}/${date.getFullYear()}`,
        listOfIdPlace
      );
    }
  }

  getProgramRegistrationStatistics(listOfIdPlace: number[], date: Date): Observable<StatisticDTO> {
    const token = getMobileToken();

    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.post<StatisticDTO>(
        `${this.root}/programmeinscription/${date.getMonth()}/${date.getFullYear()}`,
        listOfIdPlace,
        options
      );
    } else {
      return this.http.post<StatisticDTO>(
        `${this.root}/programmeinscription/${date.getMonth()}/${date.getFullYear()}`,
        listOfIdPlace
      );
    }
  }

  /** REPORTING**/
  /** Suivi **/
  getReportingRegistrationStatistics(reportingDto: ReportingDto): Observable<StatisticDTO> {
    return this.http.post<StatisticDTO>(
      `${this.root}/reporting/inscription/activite`,
      reportingDto
    );
  }

  getReportingRegistrationGenderStatistics(reportingDto: ReportingDto): Observable<StatisticDTO> {
    return this.http.post<StatisticDTO>(`${this.root}/reporting/inscription/genre`, reportingDto);
  }

  getReportingNbInscritByEventByTranche(reportingDto: ReportingDto) {
    return this.http.post<ReportingBarCard>(`${this.root}/reporting/sessionByEvent/`, reportingDto);
  }

  getReportingPresenceByEventByMonth(reportingDto: ReportingDto) {
    return this.http.post<ReportingBarCard>(
      `${this.root}/reporting/presenceByEvent/`,
      reportingDto
    );
  }

  /** Reinscription **/
  getReportingNewOldInscritByEvent(reportingDto: ReportingDto) {
    return this.http.post<ReportingBarCard>(
      `${this.root}/reporting/newOldInscritByEvent/`,
      reportingDto
    );
  }

  getReportingNewOldInscritBySex(reportingDto: ReportingDto) {
    return this.http.post<ReportingBarCard>(
      `${this.root}/reporting/newOldInscritBySex/`,
      reportingDto
    );
  }

  /** Autre **/
  getReportingNumberEvents(reportingDto: ReportingDto) {
    return this.http.post<StatisticDTO>(`${this.root}/reporting/numberEvents/`, reportingDto);
  }

  getReportingSexByEvent(reportingDto: ReportingDto) {
    return this.http.post<ReportingBarCard>(`${this.root}/reporting/sexByEvent/`, reportingDto);
  }
}

import {DisplayDashboardEnum} from '../enums/display-dashboard.enum';
import {DashboardItemGraph} from './dashboard-item-graph.model';

export class DashboardItem {
  /**
   * titre de l'item
   */
  label: string;

  /**
   * displayValue: valeur principal
   */
  displayValue: string;

  optionalDisplayValue?: string;

  /**
   * values: list des données du graphe;
   */
  values?: DashboardItemGraph[];

  /**
   * les valeur sont des pourcentage ?
   */
  isPercentage: boolean;

  /**
   * progress?: progression;
   */
  progress?: number;

  /**
   * displayType? : ['graphe round','couleur']
   */
  public displayType?: DisplayDashboardEnum;

  leftLabel?: string;
  rightLabel?: string;

  constructor(options: {
    label: string;
    displayValue: string;
    isPercentage: boolean;
    optionalDisplayValue?: string;
    values?: DashboardItemGraph[];
    progress?: number;
    displayType?: DisplayDashboardEnum;
    leftLabel?: string;
    rightLabel?: string;
  }) {
    this.label = options.label;
    this.displayValue = options.displayValue;
    this.optionalDisplayValue = options.optionalDisplayValue;
    this.values = options.values;
    this.progress = options.progress;
    this.displayType = options.displayType ? options.displayType : DisplayDashboardEnum.GRAPH;
    this.leftLabel = options.leftLabel;
    this.rightLabel = options.rightLabel;
    this.isPercentage = options.isPercentage;
  }
}
